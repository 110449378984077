<template>
  <div class="page_title mb-2">
    <div class="container">
      <div class="row">
        <div class="col-md-12 pt-2">
          <!-- Ensure title exists before trying to capitalize it -->
          <h1>
            {{ title ? title[0].toUpperCase() + title.slice(1) : "" }}
          </h1>
          <ul class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/">Home </a>
              <span class="divider">&nbsp;</span>
            </li>
            <li class="breadcrumb-item">
              <a :href="`/${type}`">{{ type }}</a>
              <span class="divider">&nbsp;</span>
            </li>
            <li class="breadcrumb-item" v-if="ShowAlias && alias">
              {{ alias }}
              <span class="divider-last">&nbsp;</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    alias: {
      type: String,
      default: null,
    },
    ShowAlias: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
