<template>
  <div>
    <NavBar v-if="!loading && debugMode" />
    <router-view
      v-if="!loading && debugMode"
      :localStoredWidgets="localStoredWidgets"
    />
    <FooterElement v-if="!loading && debugMode" />

    <MaintenancePage v-if="!loading && !debugMode" />
    <!-- <div v-else>Loading...</div> -->
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import FooterElement from "./components/Shared/FooterElement.vue";
import NavBar from "./components/Shared/NavBar.vue";
import MaintenancePage from "./views/MaintenancePage.vue";

export default {
  name: "App",
  components: {
    NavBar,
    FooterElement,
    MaintenancePage,
  },
  data() {
    return {
      // localStoredWidgets: null,
      settingsData: null,
      loading: true,
      debugMode: false,
      showPopup: false,
    };
  },
  async mounted() {
    // Check if debug mode is enabled
    this.debugMode = localStorage.getItem("debug") === "1";

    if (this.debugMode && !localStorage.getItem("popupDismissed")) {
      this.showPopup = true;
    }

    // Fetch data when the component is mounted only if not in maintenance mode
    if (this.debugMode) {
      await this.fetchData();
      AOS.init({
        duration: 600,
        easing: "ease-in-out",
        once: true,
        mirror: false,
      });
    } else {
      this.loading = false; // Set loading to false if maintenance page is shown
    }
  },
  updated() {
    AOS.refresh();
  },
  methods: {
    async fetchData() {
      const storedVersion = localStorage.getItem("dataVersion");
      const storedWidgets = localStorage.getItem("widgets");
      const parsedStoredWidgets = storedWidgets
        ? JSON.parse(storedWidgets)
        : null;

      try {
        // const baseUrl = this.$apiBaseUrl;
        // const accessKey = this.$apiAccessKey;
        // const url = `${baseUrl}website/website_api/settings?debug=1&access_key=${accessKey}`;

        // const response = await fetch(url, {
        //   method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //     Accept: "application/json",
        //   },
        // });

        // if (!response.ok) {
        //   throw new Error(`HTTP error! Status: ${response.status}`);
        // }
        const response = await fetch(
          "/apijson/assets/settings.json?v=" + Date()
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const newVersion = data.version;

        // Check if we need to update localStorage
        if (newVersion !== storedVersion || !parsedStoredWidgets) {
          this.localStoredWidgets = data.widgets;
          localStorage.setItem("widgets", JSON.stringify(data.widgets));
          localStorage.setItem("dataVersion", newVersion);
        } else {
          this.localStoredWidgets = parsedStoredWidgets;
        }

        this.loading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.loading = false; // Ensure loading is false on error
      }
    },
  },
};
</script>
