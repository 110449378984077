<template>
 <section class="popup">
  <div class="popup-overlay" @click="dismissPopup">
    <div class="popup-content row" @click.stop>
      <div class="col-lg-7">
        <button class="close-btn" @click="dismissPopup">X</button>
        <img
          src="./assets/img/popup.png"
          alt="Popup Image"
          class="popup-image"
        />
      </div>
      <div class="col-lg-5 mt-5">
        <h2>Nurturing Young Minds: Quality Child Care for Every Child</h2>
        <p>Our state-of-the-art healthcare facilities...</p>
        <button class="read-more-btn" @click.prevent="readMore">Read More</button>
      </div>
    </div>
  </div>
 </section>
</template>

<script>
export default {
  name: "InitialPopup",
  methods: {
    dismissPopup() {
      this.$emit("close"); // Emit a close event to the parent
      //   localStorage.setItem("popupDismissed", "true"); // Save popup status
    },
    readMore() {
      this.$router.push({
        path: "/",
      });
      this.dismissPopup(); // Close the popup after redirecting
    },
  },
};
</script>

