<template>
  <section id="doctors" class="doctorshc section light-background">
    <!-- Section Title -->
    <div class="container section-title aos-init" data-aos="fade-up">
      <h2>Departments Doctors</h2>
      <p>
        City Hospital (CHL) offers a diverse range of medical services through its specialized departments, ensuring comprehensive care for all patients.
      </p>
    </div>
    <!-- End Section Title -->
    <div class="container">
      <div class="row gy-4">
        <div
          v-for="(department, index) in departments"
          :key="index"
          class="col-lg-2 col-md-6 aos-init"
          data-aos="fade-up"
          :data-aos-delay="100 * (index + 1)"
        >
          <a >
            <div class="dept-card text-center py-4">
              <div class="stat-img p-2">
                <i class="fas fa-stethoscope"></i>
              </div>
              <div class="text-center">
                <h3 style="font-size: 16px; font-weight: bold; color: #00a550">
                  {{ department.name }}
                </h3>
              </div>
            </div>
          </a>
        </div>
        <div
          class="col-lg-2 col-md-6 aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div class="pt-5">
            <div class="text-center pt-4">
              <a class="all-btn px-5 py-2" @click.prevent="gotoAllDepartments" 
                >Departments</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "DoctorsHomeSection",
  props: {
    departments: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    gotoAllDepartments() {
      this.$router.push({
        path: "/departments",
      });
    },
  },
};
</script>

