<template>
  <section id="about" class="about section light-background">
    <!-- Section Title -->
    <div class="container section-title aos-init" data-aos="fade-up">
      <h1>About Us<br /></h1>
      <p>
        Necessitatibus eius consequatur ex aliquid fuga eum quidem sint
        consectetur velit
      </p>
    </div>
    <!-- End Section Title -->

    <div class="container">
      <!-- First row -->
      <div class="row">
        <div class="col-lg-6">
          <div class="left">
            <img
              src="../assets/img/abouts.png"
              class="img-left img-fluid"
              alt="City Hospital1"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="right">
            <img
              src="../assets/img/abouts1.png"
              class="img-right img-fluid"
              alt="City Hospital2"
            />
          </div>
        </div>
        <div class="mt-3">
          <h4>About City Hospital</h4>
          <p>
            City Hospital has all the characteristics of a world-class hospital
            with wide range of services and specialists, equipments and
            technology, ambience and service quality.The hospital is a showcase
            of synergy of medical technology and advances in ICT through
            paperless medical records. The skilled nurses, technologists and
            administrators of City Hospital. <br />
            <br />
            City Hospital has all the characteristics of a world-class hospital
            with wide range of services and specialists, equipments and
            technology, ambience and service quality.The hospital is a showcase
            of synergy of medical technology and advances in ICT through
            paperless medical records. The skilled nurses, technologists and
            administrators of City Hospital. City Hospital has all the
            characteristics of a world-class hospital with wide range of
            services and specialists, equipments and technology, ambience and
            service quality. The hospital is a showcase of synergy of medical
            technology and advances in ICT through paperless medical records.
            The skilled nurses, technologists and administrators of City
            Hospital. <br />
            <br />
            City Hospital has all the characteristics of a world-class hospital
            with wide range of services and specialists, equipments and
            technology, ambience and service quality.The hospital is a showcase
            of synergy of medical technology and advances in ICT through
            paperless medical records. The skilled nurses, technologists and
            administrators of City Hospital.
          </p>
        </div>
      </div>
      <!-- second -->
      <div class="second row mt-5">
        <div class="col-lg-6">
          <div class="left">
            <img
              src="../assets/img/abouts3.png"
              class="img-left img-fluid"
              alt="City Hospital1"
            />
          </div>
        </div>
        <div class="col-lg-6 about-text">
          <h4>Our Mission</h4>
          <p>
            We are dedicated to meeting the needs of:Our patient :excellent and
            cost-effective healthcare Our staff :continuing development and
            welfare Our nation :partnership in promoting health in Bangladesh.
          </p>
          <h4>Our Vision</h4>
          <p>
            "To be a renowned organization at the leading edge of Medicine,
            providing quality healthcare to meet our nation's aspirations."
          </p>
        </div>
      </div>
      <!-- third -->
      <div class="third row mt-5">
        <div class="col-lg-6 about-text">
          <h4>Emergency & Outdoor</h4>
          <p>
            "To be a renowned organization at the leading edge of Medicine,
            <br />
            providing quality healthcare to meet our nation's aspirations."
          </p>
        </div>
        <div class="col-lg-6">
          <div class="right">
            <img
              src="../assets/img/abouts4.png"
              class="img-right img-fluid"
              alt="City Hospital2"
            />
          </div>
        </div>
      </div>
      <!-- fouth -->
      <div class="fourth row mt-5">
        <div class="col-lg-6">
          <div class="left">
            <img
              src="../assets/img/abouts5.png"
              class="img-left img-fluid"
              alt="City Hospital1"
            />
          </div>
        </div>
        <div class="col-lg-6 about-text">
          <h4>Asthar Thikana</h4>
          <p>
            We are dedicated to meeting the needs of: <br />
            Our patient :excellent and cost-effective healthcare <br />
            Our staff :continuing development and welfare <br />
            Our nation :partnership in promoting health in Bangladesh.
          </p>
        </div>
      </div>
      <!-- Fifth -->
      <div class="fifth row mt-5">
        <div class="col-lg-6 about-text">
          <h4>ECG & Echo</h4>
          <p>
            "To be a renowned organization at the leading edge of Medicine,
            providing quality healthcare to meet our nation's aspirations."
          </p>
        </div>
        <div class="col-lg-6">
          <div class="right">
            <img
              src="../assets/img/abouts6.png"
              class="img-right img-fluid"
              alt="City Hospital2"
            />
          </div>
        </div>
      </div>
      <!-- sixth -->
      <div class="sixth row mt-5 mb-5">
        <div class="col-lg-6">
          <div class="left">
            <img
              src="../assets/img/abouts7.png"
              class="img-left img-fluid"
              alt="City Hospital1"
            />
          </div>
        </div>
        <div class="col-lg-6 about-text">
          <h4>Lab of Pathology</h4>
          <p>
            We are dedicated to meeting the needs of: <br />
            Our patient :excellent and cost-effective healthcare
            <br />
            Our staff :continuing development and welfare <br />
            Our nation :partnership in promoting health in Bangladesh.
          </p>
        </div>
      </div>
      <!-- 7th -->
      <div class="sth row mt-5">
        <div class="col-lg-6 mt-6 about-text">
          <h4>Cafeteria</h4>
          <p>
            "To be a renowned organization at the leading edge of Medicine,
            <br />
            providing quality healthcare to meet our nation's aspirations."
          </p>
        </div>
        <div class="col-lg-6">
          <div class="right">
            <img
              src="../assets/img/abouts8.png"
              class="img-right img-fluid"
              alt="City Hospital2"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>
