<template>
  <PageTitle :title="doctor.name" type="doctors" />

  <div class="container">
    <div class="row mt-5">
      <div class="col-md-3 col-sm-12">
        <div  >
          <img
           class="drd-doctor-image py-3"
            :src="
              doctor.profile_photo
                ? `${this.$apiBaseUrl}fdrives/sid/cityhospital/doctors/${doctor.profile_photo}`
                : defaultImage
            "
            :alt="doctor.name"
           
          />
        </div>
      </div>
      <div class="col-md-9 col-sm-12">
        <div class="profile_name p-2">
          <h1 class="font-weight-bold">
            <strong>{{ doctor.name }}</strong>
          </h1>

          <h2 class="font-weight-bold text-black">
            Information
            <hr />
          </h2>
          <h4>Degree:</h4>
          <p title="Degree">
            {{ doctor.degree }}
          </p>
          <h4>Speciality</h4>
          <p>{{ doctor.speciality }}</p>

          <h1>Visit Time and Fee</h1>
          <hr />

          <p style="font-size: 16px; font-weight: bold">Visit Time</p>
          <p>{{ doctor.available_day }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
import PageTitle from "./PageTitle.vue";

export default {
  name: "DoctorDetails",
  components: {
    PageTitle,
  },
  data() {
    return {
      doctor: {},
      defaultImage:
        "http://cityhospital.techecosys.net//includes/themes/primary/hospital/hospital/assets/img/doctors.png",
    };
  },
  methods: {
    async fetchDoctorDetails() {
      const doctorId = this.$route.params.id;
      let url = `${this.$apiBaseUrl}pip/pip_api/doctors/${doctorId}?access_key=${this.$apiAccessKey}`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        this.doctor = result.doctor || {};
        console.log("Doctor details:", this.doctor);
        useHead({
          title: this.doctor.name + " - City Hospital ",
          meta: [
            {
              name: "description",
              content:
                this.doctor.name +
                " - City Hospital, " +
                this.doctor.speciality,
            },
            {
              name: "keywords",
              content: this.doctor.name + " , City Hospital ",
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    },
    goBack() {
      this.$router.go(-1); // Go back to the previous page
    },
  },
  mounted() {
    this.fetchDoctorDetails();
  },
};
</script>
