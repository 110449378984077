<template>
  <PageTitle title="Appointment" type="appointment" />
 
  <div class="col-md-6 my-2 container">
    <div class="alert alert-success" v-if="isSubmitted">
          <h2 class="text-success">Successfully submitted !</h2>
        </div>
   <div v-else>
    <form @submit.prevent="handleSubmit" class="form-horizontal">
      <fieldset>
        <div class="form-row">
          <div class="form-group col-md-12 m-2">
            <input
              v-model="form.first_name"
              required
              type="text"
              class="form-control"
              id="first_name"
              placeholder="Your First Name"
            />
          </div>
          <div class="form-group col-md-12 m-2">
            <input
              v-model="form.last_name"
              required
              type="text"
              class="form-control"
              id="last_name"
              placeholder="Your Last Name"
            />
          </div>
          <div class="form-group col-md-12 m-2">
            <input
              v-model="form.phone"
              required
              type="text"
              class="form-control"
              id="phone"
              placeholder="Mobile"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-12 m-2">
            <input
              v-model="form.email"
              required
              type="email"
              class="form-control"
              id="email"
              placeholder="Your Email"
            />
          </div>
        </div>

        <div class="form-group col-md-12 m-2">
          <input
            v-model="form.send_copy_to_yourself"
            type="checkbox"
            id="send_copy_to_yourself"
          />
          <label class="m-2" for="send_copy_to_yourself">
            Send copy to yourself
          </label>
        </div>

        <div class="form-group m-2">
          <textarea
            v-model="form.message"
            required
            class="form-control"
            id="message"
            rows="5"
            placeholder="Your Message"
          ></textarea>
        </div>
        <button type="submit" class="btn btn-primary m-2">
          Book Appointment
        </button>
      </fieldset>
    </form>
   </div>
  </div>
</template>

<script>
import PageTitle from './PageTitle.vue';

export default {
  name: "MakeAppointment",
  components:{
    PageTitle
  },
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        message: "",
        feedback_type_id: "2", // Default value for Appointment
        send_copy_to_yourself: false,
        ip: "",
      },
      isSubmitted: false,
      contactContent: {}, // To store the contact content from the API
    };
  },
  methods: {
    async handleSubmit() {
      const formData = new FormData();
      formData.append("first_name", this.form.first_name);
      formData.append("last_name", this.form.last_name);
      formData.append("email", this.form.email);
      formData.append("phone", this.form.phone);
      formData.append("message", this.form.message);
      formData.append("feedback_type_id", this.form.feedback_type_id); // Always Appointment
      formData.append("send_copy_to_yourself", this.form.send_copy_to_yourself);
      formData.append("ip", this.form.ip);

      const apiUrl =
        this.$apiBaseUrl +
        "website/website_api/feedback?access_key=" +
        this.$apiAccessKey;

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          body: formData,
        });

        const result = await response.json();

        if (response.ok) {
          // Clear the form inputs after successful submission
          this.isSubmitted = true;
          this.resetForm();
        } else {
          alert("Failed to submit feedback");
          console.error(result);
        }
      } catch (error) {
        console.error("Error submitting feedback:", error);
      }
    },
    resetForm() {
      this.form = {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        message: "",
        feedback_type_id: "2", // Reset to default Appointment value
        send_copy_to_yourself: false,
        ip: "",
      };
    },
  },
};
</script>
