<template>
  <PageTitle :title="albumTitle" type="gallery" />
  <div class="album-details container p-5">
    <h2 class="text-center">{{ description }}</h2>
  </div>

  <swiper
    :slidesPerView="3"
    :spaceBetween="20"
    :loop="true"
    :centeredSlides="true"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }"
    :pagination="false"
    :navigation="false"
    :modules="modules"
    class="mySwiper"
  >
    <SwiperSlide v-for="(image, index) in albumImages" :key="index">
      <img
        :src="getFullImagePath(image.file_path)"
        :alt="image.title"
        class="gallery-slider-image m-5"
      />
    </SwiperSlide>
  </swiper>

  <!-- Centered Button -->
  <div class="gd-back-btn">
    <button @click="goBack" class="all-btn px-1 py-2">
      Back to Albums
    </button>
  </div>
</template>

<script>
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import PageTitle from "./PageTitle.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    PageTitle,
  },
  data() {
    return {
      albumTitle: "",
      albumImages: [],
    };
  },
  created() {
    this.fetchAlbumDetails();
  },
  methods: {
    async fetchAlbumDetails() {
      const albumId = this.$route.params.id;
      try {
        const response = await axios.get(
          `https://cityhospital.autofyapps.com/website/website_api/album_image/${albumId}?access_key=123456789&debug=1`
        );
        if (response.data.code === 200) {
          this.albumTitle = response.data.album.title;
          this.description = response.data.album.description;
          this.albumImages = response.data.album_images;
        }
      } catch (error) {
        console.error("Error fetching album details:", error);
      }
    },
    goBack() {
      this.$router.push("/gallery");
    },
    getFullImagePath(path) {
      return `https://cityhospital.autofyapps.com/${path}`;
    },
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
};
</script>


