<template>
  <InitialPopup v-if="showPopup" @close="handleClosePopup" />

  <div class="homepage-bg">
    <SliderComponent />
    <!-- <NoticesSection /> -->

    <!-- Dynamic Sections -->
    <div class="homepage-bg" v-if="homePageBlockItems.length">
      <div
        v-for="block in homePageBlockItems"
        :key="block.id"
        class="home-page-block"
      >
        <!-- Conditionally render LatestNewsHomeSection or raw HTML description -->

        <component
          v-if="
            block.widget_element_path === 'LatestNewsHomeSection' ||
            block.widget_element_path === 'LatestServiceHomeSection' ||
            block.widget_element_path === 'LatestBlogSection' ||
            block.widget_element_path === 'DoctorsHomeSection' ||
            block.widget_element_path === 'StatsHomeSection' ||
            block.widget_element_path === 'GalleryHomeSection'
          "
          :is="block.component"
          :title="block.title"
          :description="block.description"
          :news="
            block.widget_element_path === 'LatestNewsHomeSection'
              ? block.items
              : []
          "
          :services="
            block.widget_element_path === 'LatestServiceHomeSection'
              ? block.items
              : []
          "
          :blogs="
            block.widget_element_path === 'LatestBlogSection' ? block.items : []
          "
          :departments="
            block.widget_element_path === 'DoctorsHomeSection'
              ? block.items
              : []
          "
          :slides="
            block.widget_element_path === 'GalleryHomeSection'
              ? block.items
              : []
          "
        />
        <div v-else v-html="block.description"></div>
      </div>
    </div>

    <div v-else>
      <p>No content available for home page blocks</p>
      <DefaultSection />
    </div>
  </div>
</template>

<script>
import SliderComponent from "./SliderComponent.vue";
import NoticesSection from "./NoticesSection.vue";
import DefaultSection from "./DefaultSection.vue";
import LatestNewsHomeSection from "./LatestNewsHomeSection.vue"; // Import LatestNewsHomeSection
import GalleryHomeSection from "./GalleryHomeSection.vue";
import LatestServiceHomeSection from "./LatestServiceHomeSection.vue";
import DoctorsHomeSection from "./DoctorsHomeSection.vue";
import StatsHomeSection from "./StatsHomeSection.vue";
import { markRaw } from "vue";
import { useHead } from "@vueuse/head";
import LatestBlogSection from "./LatestBlogSection.vue";
import InitialPopup from "@/InitialPopup.vue";

export default {
  name: "HomePage",
  components: {
    SliderComponent,
    NoticesSection,
    DefaultSection,
    LatestNewsHomeSection, 
    GalleryHomeSection,
    LatestServiceHomeSection,
    DoctorsHomeSection,
    StatsHomeSection,
    LatestBlogSection,
    InitialPopup,
  },
  props: {
    localStoredWidgets: {
      type: Object,
      required: true, // Make sure this prop is passed in
    },
  },
  data() {
    return {
      homePageBlockItems: [],
      debugMode: false,
      showPopup: false,
    };
  },
  setup() {
    useHead({
      title: "Welcome to City Hospital",
      meta: [
        {
          name: "description",
          content: "Explore latest news of City Hospital.",
        },
        {
          name: "keywords",
          content: "City Hospital Limited, news, hospital, departments, medical services",
        },
      ],
    });
  },
  mounted() {
    this.debugMode = localStorage.getItem("debug") === "1";

    if (this.debugMode && !localStorage.getItem("popupDismissed")) {
      this.showPopup = true;
    }else if (!localStorage.getItem("popupShown")) {
    // Show popup for first-time visitors and set a flag in localStorage
    this.showPopup = true;
    localStorage.setItem("popupShown", "true");
  }

    // Use the localStoredWidgets prop to populate the homepage block items
    if (this.localStoredWidgets && this.localStoredWidgets.home_page_block) {
      const homePageBlockItemsArray = Object.values(
        this.localStoredWidgets.home_page_block
      );

      // Map items and load components asynchronously
      this.homePageBlockItems = homePageBlockItemsArray.map((item) => ({
        id: item.id,
        alias: item.alias,
        widget_element_path: item.widget_element_path,
        title: item.title,
        description: item.description,
        children: item.items || [], // Include children for dropdown
        component: this.getComponent(item.widget_element_path),
        items: item.items || [],
      }));
      // console.log("homepage", this.homePageBlockItems);
    }
  },
  methods: {
    getComponent(widget_element_path) {
      if (widget_element_path === "LatestNewsHomeSection") {
        return markRaw(LatestNewsHomeSection);
      }
      if (widget_element_path === "LatestServiceHomeSection") {
        return markRaw(LatestServiceHomeSection);
      }
      if (widget_element_path === "LatestBlogSection") {
        return markRaw(LatestBlogSection);
      }
      if (widget_element_path === "GalleryHomeSection") {
        return markRaw(GalleryHomeSection);
      }
      if (widget_element_path === "DoctorsHomeSection") {
        return markRaw(DoctorsHomeSection);
      }
      if (widget_element_path === "StatsHomeSection") {
        return markRaw(StatsHomeSection);
      }

      // Default fallback to DefaultSection
      return DefaultSection;
    },
    handleClosePopup() {
    // Close the popup and set a dismissed flag
    this.showPopup = false;
    localStorage.setItem("popupDismissed", "true");
  },
  },
};
</script>
