<template>
    <div class="notices">
      <div class="container">
        <div class="marquee">
          Multi Slice CT Scan Machine in City Hospital Ltd. <i class="far fa-newspaper"></i>
          High Flow Oxygen Therapy Device for Corona Patient <i class="far fa-newspaper"></i>
          Home Quarantine time, Chest Medicine & Medicine Treatment Consultation over Telephone <i class="far fa-newspaper"></i>
          Home Quarantine time, Diabetes Treatment Consultation over Telephone <i class="far fa-newspaper"></i>
          Emergency period treatment Consultant over telephone from Home Stay <i class="far fa-newspaper"></i>
          Awareness for Corona Virus-COVID-19 <i class="far fa-newspaper"></i>
          Multi Slice CT Scan Machine in City Hospital Ltd
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "NoticesSection",
  };
  </script>
  
  <style scoped>
  .notices {
    overflow: hidden;
    white-space: nowrap;
  }
  
  .marquee {
    display: inline-block;
    animation: marquee 10s linear infinite;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  </style>
  