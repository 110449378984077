<template>
  <section id="news" class="news section light-background">
    <div
      class="container section-title aos-init aos-animate"
      data-aos="fade-up"
    >
      <h2>Latest Services</h2>
      <p>Take a look at our services and choose what's best for you.</p>
    </div>
    <div class="container">
      <swiper
        :slidesPerView="slidesPerView"
        :spaceBetween="20"
        :loop="true"
        :centeredSlides="true"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }"
        :pagination="false"
        :navigation="false"
        :modules="modules"
        class="mySwiper"
        :breakpoints="breakpoints"
      >
        <swiper-slide v-for="(slide, index) in services" :key="index">
          <div class="news_member">
            <div>
              <div class="news_img">
                <img
                  class="img-fluid news_image"
                  :src="`${slide.image_url}`"
                  :alt="slide.title"
                />
              </div>
              <div class="team_details text-center">
                <a :href="'services/' + slide.url">
                  <h3>
                    {{ truncateTitle(slide.title, 50) }}
                  </h3>
                </a>
                <div class="text-center button_profile py-3">
                  <a
                    @click.prevent="goToServiceDetails(slide.alias)"
                    class="btn btn-main btn-icon btn-round-full"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <p v-html="truncateText(slide.fulltext, 50)"></p>
          </div>
        </swiper-slide>
      </swiper>
      <div class="text-center py-5">
        <a class="all-btn px-5 py-3" @click.prevent="gotoAllServices"
          >All Services
        </a>
      </div>
    </div>
  </section>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// Import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default {
  name: "LatestServiceHomeSection",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    services: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
      breakpoints: {
        // Define breakpoints for responsive design
        640: {
          // For mobile devices
          slidesPerView: 1, // Show 1 slide per view on mobile
        },
        768: {
          // For tablets
          slidesPerView: 2, // Show 2 slides per view on tablet
        },
        1024: {
          // For larger devices
          slidesPerView: 3, // Show 3 slides per view on desktop
        },
      },
    };
  },
  methods: {
    goToServiceDetails(alias) {
      this.$router.push({
        name: "SinglePage",
        params: { contentType: "services", alias: alias },
      });
    },
    truncateTitle(title, limit) {
      return title.length > limit ? title.substring(0, limit) + "..." : title;
    },
    gotoAllServices() {
      this.$router.push({
        path: "/services",
      });
    },
    truncateText(html, wordLimit) {
      const div = document.createElement("div");
      div.innerHTML = html;
      const textContent = div.textContent || div.innerText || "";

      const words = textContent.split(/\s+/);
      if (words.length > wordLimit) {
        const truncated = words.slice(0, wordLimit).join(" ") + "...";
        return truncated;
      }
      return html;
    },
  },
};
</script>
