<template>
  <section id="hero" class="hero section">
    <div id="hero-carousel" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">
      <div v-for="(item, index) in sliderItems" :key="item.id" class="carousel-item" :class="{ active: index === 0 }">
        <!-- Image with correct binding -->
        <img :src="item.imageUrl" class="d-block w-100" alt="city hospital slider" />
      </div>

      <a class="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
      </a>

      <a class="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
      </a>

    </div>
  </section>
</template>

<script>
export default {
  name: "SliderComponent",
  data() {
    return {
      widgets: null,
      sliderItems: [], 
    };
  },
  mounted() {
    this.loadSliderItems();
  },
  methods: {
    loadSliderItems() {

      // Check if widgets are stored in localStorage
      const storedWidgets = localStorage.getItem("widgets");

      if (storedWidgets) {
        try {
          this.widgets = JSON.parse(storedWidgets);

          // Check if the slider exists inside widgets data
          if (
            this.widgets &&
            this.widgets.slider &&
            this.widgets.slider["0"] &&
            this.widgets.slider["0"].items
          ) {
            // Extract and map the slider items to the component's data structure
            const sliderItemsArray = this.widgets.slider["0"].items;

            this.sliderItems = sliderItemsArray.map((item) => ({
              id: item.id,
              title: item.title,
              imageUrl: `${this.$apiBaseUrl}${item.image}`, // Combine base URL with image path
              alias: item.alias,
              url: `${this.$apiBaseUrl}${item.url}`, // Ensure URL is absolute
            }));
          } else {
            console.error("Slider data is missing or malformed in widgets");
          }
          // console.log(this.sliderItems);
        } catch (error) {
          console.error("Error parsing widgets from localStorage:", error);
        }
      } else {
        console.error("No widgets found in localStorage");
      }
    },
  },
};
</script>

