<template>
  <PageTitle :title="contentType" :type="contentType" />

  <div class="container p-5 mt-2">
    <div class="row">
      <div class="col-lg-8">
        <div class="row align-items-start">
          <!-- Content Section -->
          <div class="my-4" v-for="item in items" :key="item.id">
            <div class="">
              <!-- Image Section with rounded corners -->
              <div class="wc-list_page_img mb-1">
                <img
                  :src="item.image_url"
                  :alt="item.title"
                  :title="item.title"
                  class="img-fluid"
                  style="border-radius: 20px; width: 100%"
                />
              </div>
              <!-- Content Section -->
              <div class="p-1">
                <!-- Title Section -->
                <div class="wc-list_page_title py-2">
                  <a
                    :href="'/' + contentType + '/' + item.alias"
                    class="text-dark font-weight-bold"
                  >
                    <h1>{{ item.title }}</h1>
                  </a>
                </div>
                <!-- Meta Info (Date, Views, Author) -->
                <div class="list_page_date text-muted">
                  <ul class="list-unstyled d-flex">
                    <li class="mr-3">
                      <i class="fas fa-calendar-alt"></i>
                      {{ item.publish_date }}
                    </li>
                    <li class="mr-3">
                      <i class="fas fa-eye"></i> {{ item.total_view }} views
                    </li>
                    <li><i class="fas fa-user"></i> Admin</li>
                  </ul>
                </div>
                <!-- Full Text Section -->
                <div class="wc-list_page_deatils mt-3">
                  <p>{{ item.fulltext }}</p>
                </div>
                <!-- Read More Button -->
                <div class="text-right">
                  <router-link
                    :to="{
                      name: 'SinglePage',
                      params: { alias: item.alias, contentType: contentType },
                    }"
                    class="btn btn-main btn-icon btn-round-full"
                  >
                    Read More <i class="icofont-simple-right ml-2"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- Pagination Controls -->
          <div class="col-lg-12 col-12">
            <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-center mb-0">
                <!-- Previous Button -->
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <a
                    class="page-link text-dark"
                    href="#"
                    aria-label="Previous"
                    @click.prevent="changePage(currentPage - 1)"
                  >
                    <span aria-hidden="true">Prev</span>
                  </a>
                </li>

                <!-- Page Numbers -->
                <li
                  class="page-item"
                  v-for="page in totalPages"
                  :key="page"
                  :class="{ active: currentPage === page }"
                >
                  <a
                    :style="currentPage === page ? activeStyle : inactiveStyle"
                    class="page-link"
                    href="#"
                    @click.prevent="changePage(page)"
                  >
                    {{ page }}
                  </a>
                </li>

                <!-- Next Button -->
                <li
                  class="page-item"
                  :class="{ disabled: currentPage === totalPages }"
                >
                  <a
                    class="page-link text-dark"
                    href="#"
                    @click.prevent="changePage(currentPage + 1)"
                  >
                    <span aria-hidden="true ">Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <!-- wc-sidebar Section -->
        <div>
          <div class="wc-sidebar">
            <!-- Popular Posts -->
            <div
              class="wc-widget"
              style="
                border-radius: 20px;
                background-color: #f6f8f9;
                padding: 25px 30px 30px 30px;
              "
            >
              <h5 class="wc-widget-title">Popular {{ contentType }}</h5>

              <!-- Card for each post -->
              <div
                v-for="post in items.slice(0, 3)"
                :key="post.title"
                class="wc-sidebar-item d-flex mb-4"
                style="
                  border: 1px solid #ddd;
                  background-color: #fff;
                  border-radius: 12px;
                  padding: 15px;
                  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                "
              >
                <!-- Post Info -->
                <div style="flex: 1; padding-right: 10px">
                  <router-link
                    :to="{
                      name: 'SinglePage',
                      params: { alias: post.alias, contentType: contentType },
                    }"
                  >
                    <p class="wc-sidebar-post-title" style="font-weight: bold">
                      {{ truncateTitle(post.title, 50) }}
                    </p>
                  </router-link>

                  <p
                    class="wc-sidebar-meta text-muted"
                    style="font-size: 0.9em"
                  >
                    <i
                      class="icon far fa-calendar-alt"
                      style="margin-right: 5px"
                    ></i>
                    {{ post.publish_date }}
                  </p>
                </div>

                <!-- Thumbnail Image -->
                <img
                  :src="post.image_url"
                  class="wc-sidebar-thumb me-3"
                  alt="popular post"
                  style="
                    border-radius: 12px;
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                  "
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Fb post -->

        <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FCityHospitalBd%2F&tabs=timeline&width=400&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
          width="400"
          height="500"
          style="border: none; overflow: hidden"
          scrolling="no"
          frameborder="0"
          allowfullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>

        <div class="add-wc-widget padding-bottom-30 mt-4">
          <img
            class="img-fluid rounded-4"
            src="https://cityhospital.autofyapps.com/fdrives/sid/cityhospital/gallery/hospiital-building-view/297a9513_s96462.webp"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "./PageTitle.vue";

export default {
  name: "WebsiteContents",
  components: {
    PageTitle,
  },
  props: {
    contentType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      currentPage: 1,
      totalPages: 1,
      itemsPerPage: 4,
      activeStyle: {
        backgroundColor: "#00a550", // Background color for active page
        color: "#fff", // Font color for active page
      },
      inactiveStyle: {
        backgroundColor: "#fff", // Background color for inactive pages
        color: "#00a550", // Font color for inactive pages
      },
    };
  },
  methods: {
    async fetchContent(page = 1) {
      try {
        let url =
          this.$apiBaseUrl +
          `website/website_api/contents/${this.contentType}?access_key=
          ${this.$apiAccessKey}&page=${page}&limit=${this.itemsPerPage}`;

        const response = await fetch(url);
        if (!response.ok)
          throw new Error(
            `Error fetching ${this.contentType}: ${response.status}`
          );

        const result = await response.json();
        this.items = result.contents;
        const paging = result.paging;

        this.totalPages = paging.pages;
        this.itemsPerPage = paging.per_page;
      } catch (error) {
        console.error(`Error fetching ${this.contentType}:`, error);
      }
    },
    truncateTitle(title, limit) {
      return title.length > limit ? title.substring(0, limit) + "..." : title;
    },

    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchContent(this.currentPage);
      }
    },
  },
  mounted() {
    this.fetchContent();
  },
};
</script>
