<!-- src/views/DynamicPage.vue -->
<template>
    <div>
 <h2>Default Section</h2>
    </div>
  </template>
  
  <script>
  export default {
    name:"DefaultSection"
  };
  </script>
  
 