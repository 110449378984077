<template>
  <div class="container pt-4 text-center">
    <div class="titel pt-5">
      <h3>Website Under Maintenance</h3>
      <p>
        We're currently performing scheduled maintenance. During this time, our
        website may be temporarily unavailable. We appreciate your patience and
        will have everything up and running soon. Thank you for understanding!
      </p>

      <br /><br />

      <img
        src="https://www.cityhospitalbd.com/includes/theme/default//img/logo.png"
        class="text-center"
        alt="maintainance page"
      />
      <br /><br />
      <h1 style="color: #ed8a1c">City Hospital Limited</h1>
    </div>

    <h4 class="text-primary">Contact Us</h4>
    <i class="fa fa-phone"></i> +88 01558220134, +88 01815 484600 <br />
    <i class="fa fa-envelope"></i> cityhosp.bd@gmail.com <br />
    <br />
    <div class="">
      <i class="fa fa-map-marker"></i> 1/8, Block-E, Lalmatia, Satmosjid Road,
      Mohammadpur, Dhaka - 1207.
    </div>
  </div>
</template>

<script>
export default {
  name: "MaintenancePage",
};
</script>
