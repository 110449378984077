<template>
  <PageTitle title="Gallery" type="gallery" />
  <div class="gallery-page container">
    <!-- <h2 class="text-center">Gallery</h2> -->
    <div class="albums d-flex">
      <div class="container">
        <div class="row gp-4">
          <div
            v-for="album in albums"
            :key="album.id"
            @click="viewAlbum(album.id)"
            class="news-card col-lg-4 col-md-4 aos-init aos-animate"
            data-aos="fade-up"
          >
            <div class="news_member">
              <div class="news_img">
                <img
                  class="img-fluid news_image"
                  :src="getFullImagePath(album.file_path)"
                  :alt="album.title"
                />
              </div>
              <div class="team_details text-center">
                <h4>
                  {{ album.title }}
                </h4>

                <div class="text-center button_profile py-3">
                  <a
                    @click.prevent="viewAlbum(album.id)"
                    class="btn btn-main btn-icon btn-round-full"
                  >
                    See Album
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PageTitle from "./PageTitle.vue";

export default {
  components: { PageTitle },
  data() {
    return {
      albums: [],
    };
  },
  created() {
    this.fetchAlbums();
  },
  methods: {
    async fetchAlbums() {
      try {
        const response = await axios.get(
          "https://cityhospital.autofyapps.com/website/website_api/album?access_key=123456789&debug=1"
        );
        if (response.data.code === 200) {
          this.albums = response.data.albums;
        }
      } catch (error) {
        console.error("Error fetching albums:", error);
      }
    },
    viewAlbum(id) {
      this.$router.push({ name: "AlbumDetails", params: { id } });
    },
    getFullImagePath(path) {
      return `https://cityhospital.autofyapps.com/${path}`;
    },
  },
};
</script>
